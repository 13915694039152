<template>
  <div class="status-dialog">
    <div class="dialog-wrapper">
      <div class="dialog-container">
        <!-- Status info -->
        <div class="dialog-body">
          <!-- Password -->
          <div class="dialog-body-row">
            <label for="password">
              {{
                displayLabelName("utilities", "messages", "password")
              }}:</label
            >
            <input
              name="password"
              type="text"
              id="password"
              autocomplete="off"
              v-model="password"
            />
          </div>
          <!-- Name -->
          <div class="dialog-body-row half-width">
            <div>
              {{ displayLabelName("utilities", "messages", "name") }}
            </div>
            <div class="checkbox slide">
              <input id="name" type="checkbox" v-model="name" />
              <label for="name"></label>
            </div>
          </div>
          <!-- Logo -->
          <div class="dialog-body-row half-width">
            <div>{{ displayLabelName("utilities", "messages", "logo") }}</div>
            <div class="checkbox slide">
              <input id="logo" type="checkbox" v-model="logo" />
              <label for="logo"></label>
            </div>
          </div>
          <!-- Inverted -->
          <div class="dialog-body-row half-width">
            <div>
              {{ displayLabelName("utilities", "messages", "inverted") }}
            </div>
            <div class="checkbox slide">
              <input id="inverted" type="checkbox" v-model="inverted" />
              <label for="inverted"></label>
            </div>
          </div>
        </div>
        <div class="dialog-close">
          <div class="dialog-action" @click="closeDialog">
            {{ displayLabelName("utilities", "messages", "cancel") }}
          </div>
          <div class="dialog-action" @click="submitExportCodes">
            {{ displayLabelName("utilities", "messages", "ok") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import globalMixin from "@/services/mixins/global/global";

export default {
  name: "ExportDialog",
  data() {
    return {
      password: "",
      name: false,
      logo: false,
      inverted: false
    };
  },
  props: {
    qrCodes: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    ...mapActions("qrCode", ["exportQrCodes"]),
    closeDialog() {
      this.$emit("closeDialog");
    },
    submitExportCodes() {
      const codes = [...this.qrCodes];
      codes.map(c => delete c.id); // remove ID from objects

      const data = {
        data: codes,
        password: this.password,
        name: Boolean(this.name),
        logo: Boolean(this.logo),
        inverted: Boolean(this.inverted)
      };

      this.exportQrCodes(data).then(response => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"]
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const contentDispositionHeader =
          response.headers["content-disposition"];
        let fileName = "qrcodes.zip";
        if (contentDispositionHeader) {
          const headerFileName = this.extractHeaderFileName(
            contentDispositionHeader
          );
          fileName = headerFileName ? headerFileName : fileName;
        }
        link.download = fileName;
        link.click();
        link.remove();

        this.$emit("closeDialog");
      });
    }
  },
  mixins: [globalMixin]
};
</script>
